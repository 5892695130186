import NetworkService from "@/services/NetworkService";
//doc https://valid-sa.atlassian.net/wiki/spaces/PDD/pages/958365697/Manual+de+Integra+o+com+VIDaaS+-+Certificado+em+Nuvem
export default class PrescriptionService {
  constructor() {
    this.network = new NetworkService();
  }

  static DOCTYPES = {
    PRESCRIPTION: "prescription",
    MEDICAL_RECORD: "medicalRecord",
  };

  static TYPES = {
    SIMPLE: "simple",
    SPECIAL_CONTROL: "special control",
    MEDICAL_CERTIFICATE: "sick note",
    EXAM_SOLICITATION: "exam request",
  };

  static DIGITAL_TYPES = {
    VIDAAS: "vidaas",
    BIRD_ID: "birdId",
  };

  types = [
    {
      id: PrescriptionService.TYPES.SIMPLE,
      name: "Receita simples",
    },
    {
      id: PrescriptionService.TYPES.SPECIAL_CONTROL,
      name: "Receita de controle especial",
    },
    {
      id: PrescriptionService.TYPES.MEDICAL_CERTIFICATE,
      name: "Atestado médico",
    },
    {
      id: PrescriptionService.TYPES.EXAM_SOLICITATION,
      name: "Solicitação de exame",
    },
  ];
  create = (params) => {
    return this.network.post(this.network.endpoints.prescriptions, params);
  };
  list = (params) => {
    return this.network.get(this.network.endpoints.prescriptions, params);
  };
  show = (id) => {
    return this.network.get(`${this.network.endpoints.prescriptions}/${id}`);
  };
  listMedications = (params) => {
    return this.network.get(
      `${this.network.endpoints.medicationNames}`,
      params
    );
  };
  listCids = (params) => {
    return this.network.get(`${this.network.endpoints.cids}`, params);
  };
  showMedication = (id) => {
    return this.network.get(`${this.network.endpoints.medicines}/${id}`);
  };
  listMedClassifications = (params) => {
    return this.network.get(this.network.endpoints.medicinesCategories, params);
  };
  preparePrescription(prescription) {
    try {
      if (prescription.drugs_extra) {
        prescription.formatted_drugs_extra = prescription.drugs_extra
          .split("|")
          .map((elem) => {
            const med = elem.split(";");
            return {
              name: med[0],
              dosage: med[1],
              dose: med[2],
              qty: med[3],
              isExtra: true,
            };
          });
      }
      return prescription;
    } catch (e) {
      console.log(e);
    }
  }
  validatePrescription = (params) => {
    return this.network.get(
      this.network.endpoints.validatePrescription,
      params
    );
  };
}
