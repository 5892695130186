<template>
  <ModalComponent id="warning_modal" title="Atenção">
    <template v-slot:body>
      <!-- DOCUMENTO NÃO CADASTRADO -->
      <div
        class="alert bg-warning-soft text-warning"
        v-if="!userSession?.document"
      >
        <p class="text-subtitle">
          <i class="fa-solid fa-exclamation-triangle fa-lg"></i>
          Complete seu cadastro
        </p>
        <p class="text-paragraph mb-0">
          Notamos que faltam algumas informações no seu cadastro para gerar
          receitas médicas e prontuários. Atualize-as e depois tente novamente
        </p>
      </div>

      <!-- CRM NÃO VALIDADO -->
      <div
        class="alert bg-warning-soft text-warning"
        v-if="userSession?.document && !userSession?.verified"
      >
        <p class="text-subtitle">
          <i class="fa-solid fa-exclamation-triangle fa-lg"></i>
          Informações inválidas
        </p>
        <p class="text-paragraph mb-0">
          Você não está habilidato a gerar receitas e prontuários. Confira suas
          informações de cadastro no Diag ou verifique junto ao Conselho Federal
          de Medicina
        </p>
      </div>
    </template>
    <template v-slot:footer>
      <button class="btn btn-outline-primary" @click="toggleModal">
        Voltar
      </button>

      <!-- DOCUMENTO NÃO CADASTRADO -->
      <button
        class="btn btn-primary"
        @click="handleRegisterDocument"
        v-if="!userSession?.document"
      >
        Completar cadastro
      </button>

      <!-- CRM NÃO VALIDADO -->
      <button
        class="btn btn-primary"
        @click="handleRegisterDocument"
        v-if="userSession?.document && !userSession?.verified"
      >
        Ir para a minha conta
      </button>
    </template>
  </ModalComponent>
</template>

<script>
import { toggleModal } from "@/components/ModalComponent.vue";
export default {
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
  },
  data() {
    return {
      toggleModal: () => toggleModal("warning_modal"),
    };
  },
  methods: {
    handleRegisterDocument() {
      this.toggleModal();
      this.$router.push(this.$paths.myAccount);
    },
  },
};
</script>
