import NetworkService from "@/services/NetworkService";
import store from "@/store";
import { toggleModal } from "@/components/ModalComponent.vue";
import { SubscriptionService } from "./SubscriptionService";
import PrescriptionService from "./PrescriptionsService";

export default class UserService {
  constructor() {
    this.network = new NetworkService();
  }

  static PROFESSIONAL_TYPES = {
    doctor: "Médico",
    dentist: "Dentista",
    other: "Outro",
  };

  static USER_TYPE = {
    admin: "Administrador",
    professional: "Profissional",
    student: "Estudante",
  };

  resetPassword(params) {
    return this.network.post(this.network.endpoints.passwordReset, params);
  }
  subscribe(params) {
    return this.network.post(this.network.endpoints.subscription, params);
  }
  cancelSubscription() {
    return this.network.post(this.network.endpoints.cancelSubscription);
  }
  list = (filters) => {
    return this.network.get(this.network.endpoints.users, filters);
  };
  create = async (params) => {
    const res = await this.network.post(this.network.endpoints.users, params);
    await store.dispatch("saveToken", res.access_token);
    const data = await this.getUserData(res.data.id);
    return { ...res, data };
  };
  update = (id, params) => {
    return this.network.put(`${this.network.endpoints.users}/${id}`, params);
  };
  show = (id, params) => {
    return this.network.get(`${this.network.endpoints.users}/${id}`, params);
  };
  getUserData = async (id) => {
    const subsService = new SubscriptionService();
    const res = await this.show(id);
    let data = res.data;

    const hasStripeSubs =
      data.subs &&
      (data.subs.stripe_status === "active" ||
        data.subs.stripe_status === "trialing");

    const hasIAPSubs =
      data.inapp_subs && data.inapp_subs.plan_status === "paid";

    data.isPremiumAccount = hasIAPSubs || hasStripeSubs;

    if (data?.phone) {
      const phone = `${data?.area_phone}${data?.phone}`;
      data.phone = phone ? phone.toPattern("phone", "(##) #####-####") : "";
    }

    if (data?.document) {
      data.document = data.document.toPattern("cpf", "###.###.###-##");
    }

    data.allowPatients =
      data.type == "professional" &&
      (data.professional_type == "doctor" ||
        data.professional_type == "dentist");

    data.account_type = UserService.USER_TYPE[data.type];
    data.professionalType =
      UserService.PROFESSIONAL_TYPES[data.professional_type];

    if (hasIAPSubs) {
      data.subsConfig = subsService.prepareSubscriptionForIAP(data.inapp_subs);
    } else if (data.subs) {
      // const res = await this.planDetails();
      data.subsConfig = subsService.prepareSubscriptionForStripe(data.subs);
    }

    if (!data.certificate_types) {
      data.certificate_types = [PrescriptionService.DIGITAL_TYPES.VIDAAS];
    } else {
      data.certificate_types = data.certificate_types.split(",");
    }

    return data;
  };
  // prepareUser = (data) => {
  //   // const hasCanceledButActive =
  //   //   data.subs &&
  //   //   data.subs.stripe_status == "canceled" &&
  //   //   moment().isBefore(data.subs.ends_at);

  //   const subsService = new SubscriptionService();

  //   const hasActiveStripeSubs =
  //     data.subs &&
  //     (data.subs.stripe_status === "active" ||
  //       data.subs.stripe_status === "trialing");

  //   const hasActiveIAPSubs =
  //     data.inapp_subs &&
  //     (data.inapp_subs.plan_status === "paid" ||
  //       data.inapp_subs.plan_status === "trial");

  //   data.isPremium = hasActiveIAPSubs || hasActiveStripeSubs;
  //   data.account_type = UserService.USER_TYPE[data.type];
  //   data.professionalType =
  //     UserService.PROFESSIONAL_TYPES[data.professional_type];
  //   data.subsConfig = hasActiveIAPSubs
  //     ? subsService.prepareSubscriptionForIAP(data.inapp_subs)
  //     : data.subs
  //     ? subsService.prepareSubscriptionForStripe(data.subs)
  //     : null;

  //   if (data.phone) {
  //     data.formattedPhone = `${data.area_phone}${data.phone}`.toPattern(
  //       "phone",
  //       "(##) #####-####"
  //     );
  //   }

  //   return data;
  // };
  syncSession = async () => {
    let data = await this.getUserData(store.state.userSession?.id);
    store.dispatch("syncSessionWithApi", data);
    return data;
  };
  canCreatePrescription = async () => {
    const data = await this.syncSession();
    if (
      data.type === "professional" &&
      data.professional_type === "doctor" &&
      (!data.document || !data?.verified)
    ) {
      toggleModal("warning_modal");
      return false;
    }
    return true;
  };
  delete = (id) => {
    return this.network.delete(`${this.network.endpoints.users}/${id}`);
  };
  login = async (params) => {
    const res = await this.network.post(this.network.endpoints.login, params);
    if (res.data.type !== "admin") {
      await store.dispatch("saveToken", res.access_token);
      const data = await this.getUserData(res.data.id);
      return { ...res, data };
    } else {
      throw { status: false, message: "Credenciais inválidas" };
    }
  };

  loginWithBearerToken = async (query) => {
    try {
      // if (!query.appWebView || !query.token || !query.user_id) {
      if (!query.token || !query.user_id) {
        // NOTE: Alterei pra false isso aqui, porque tava mantendo ele "logado" mesmo sem ter uma session (Vinnie)
        return false;
      }
      const token = query.token;
      const userId = query.user_id;
      // if (!store.state.userSession) {
      //NOTE: se der erro em alguma coisa, é pq essa linha servia para alguma coisa

      await store.dispatch("saveToken", token);
      const data = await this.getUserData(userId);
      if (data.type !== "admin") {
        await store.dispatch("saveSession", { data, access_token: token });
        return true;
      }
      return false;
      // }
    } catch (e) {
      console.log("loginWithBearerToken", e);
      return false;
    }
  };
  forgotPassword = (params) => {
    return this.network.post(this.network.endpoints.forgotPassword, params);
  };
  logout = () => {
    return this.network.post(`${this.network.endpoints.users}/logout`);
  };
  settings = (key) => {
    return this.network.get(`${this.network.endpoints.settings}/${key}`);
  };
  updateSettings = (key, value) => {
    return this.network.put(`${this.network.endpoints.settings}/${key}`, {
      value,
    });
  };
  homeInfo = () => {
    return this.network.get(`${this.network.endpoints.homeInfo}`);
  };
  listCrashes = () => {
    return this.network.get(`${this.network.endpoints.appCrashes}`);
  };
  showCrash = (id) => {
    return this.network.get(`${this.network.endpoints.appCrashes}/${id}`);
  };
  validateCoupon = (coupon) => {
    return this.network.get(
      this.network.endpoints.coupons,
      { type: "web", coupon },
      false
    );
  };
  planDetails = () => {
    return this.network.get(`${this.network.endpoints.planDetails}`);
  };
  getStatus = (key) => {
    switch (key) {
      case "active":
      case "trialing":
      case "canceled_trialing":
      case "canceled_active":
        return "Assinante";
      case "incomplete":
        return "Incompleto";
      case "incomplete_expired":
        return "Expirado";
      case "past_due":
        return "Vencido";
      case "unpaid":
        return "Não pago";
      case "paused":
        return "Pausado";
      default:
        break;
    }
  };
  async listFavorites() {
    let session = store.state.userSession;

    let res = await this.network.get(`${this.network.endpoints.myFavorites}`);

    res.data.map((elem) => {
      elem.firstCategoryIcon = `${process.env.VUE_APP_IMG_BASE_URL}${elem.content.first_category.image_url}`;
      elem.disabled =
        elem.content.only_subscribers && !session?.isPremiumAccount;
    });

    return res;
  }
  listNotifications = (params) => {
    return this.network.get(this.network.endpoints.notifications, params);
  };
  listUnseenNotifications = () => {
    return this.network.get(
      this.network.endpoints.unseenNotifications,
      {},
      false
    );
  };
  feedback = (params) => {
    return this.network.post(this.network.endpoints.feedback, params);
  };
}
